/** @jsx jsx */
import { Box, jsx, Grid } from "theme-ui";
import React, { useCallback } from "react";
import { PageContentNonMemoized } from "gatsby-plugin-hfn-profile/components/PageContentNonMemoized";
import DonationLayout from "../layout/DonationLayout";
import { eventCallbackTriggers } from "../templates/wppage";

const HeartfulnessInstituteDonationUsaMobile = () => {
  const pageContent = (id, modalTitle) => {
    const titletext = modalTitle || "Heartfulness Donation HI, USA";
    return `<gatsby_donation donationId =  ${id} btntext = "${titletext}" btnBg = #4d8f15 colortext = black colorprimary = forestgreen colorsecondary = darkgreen colorbackground = white colormuted = #f6f6f6 colorhighlight = #efeffe titletext = "General Donation - HI, USA" />`;
  };

  const PageContent = React.memo(PageContentNonMemoized);

  const eventCallback = useCallback(
    (event) => eventCallbackTriggers("Heartfulness_Donation_HI,_USA", event),
    []
  );

  return (
    <DonationLayout
      seoData={{
        title:
          "General donations to Heartfulness institute, USA [Mobile] - Donations",
      }}
    >
      <Grid
        columns={1}
        style={{
          paddingLeft: "40px",
          paddingRight: "40px",
        }}
      >
        <Box style={{ color: "#2d3550", textAlign: "center" }}>
          <div>
            <h1
              style={{
                color: "#2d3550",
                fontSize: "1.7em",
                fontWeight: "600",
                marginTop: "40px",
              }}
            >
              General donations to Heartfulness institute, USA
            </h1>
          </div>
        </Box>
        <Box>
          <PageContent
            eventCallback={eventCallback}
            pageContent={pageContent(17, "Donate Now")}
          />
        </Box>
      </Grid>
    </DonationLayout>
  );
};

export default HeartfulnessInstituteDonationUsaMobile;
